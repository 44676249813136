<template>
  <div class="search-page">
    <!-- <ais-configure></ais-configure> -->
    <!-- <ais-state-results> -->
    <!-- <template #default="state"> -->
    <form class="form-search" id="initial_search">
      <!-- <ais-search-box
            class="search-title-searchbox"
            :class-names="{
              'ais-SearchBox-submit': 'display-none',
              'ais-SearchBox-reset': 'display-none',
              'ais-SearchBox-resetIcon': 'display-none',
            }"
          >
            <div slot-scope="{ currentRefinement, isSearchStalled, refine }"> -->
      <input
        class="search-input"
        placeholder="Search 108,198,785 songs"
        type="text"
        v-model="query"
        @input="searchData($event.currentTarget.value)"
        @focus="onFocusSearchInput"
        @blur="onBlurSearchInput(query)"
        @keydown.prevent.stop.enter="() => {}"
      />
      <!-- <span :hidden="!isSearchStalled">Loading...</span> -->
      <!-- </div> -->
      <!-- </ais-search-box> -->
      <a href="#" class="form-close"
        ><img
          class="desktop_form_close"
          src="@/assets/img/close.svg"
          alt="close"
      /></a>
    </form>

    <div class="note"><strong>Just begin typing . . .</strong></div>

    <div v-show="query">
      <div class="search-tab-menu">
        <a
          @click.prevent="selectedTab = 'ARTIST'"
          :class="{ active: selectedTab === 'ARTIST' }"
          href="#artists"
          >Artists</a
        >
        <a
          @click.prevent="selectedTab = 'TITLE'"
          :class="{ active: selectedTab === 'TITLE' }"
          href="#titles"
          >Titles</a
        >
      </div>

      <div class="search-results-container">
        <div class="search-results-headings">
          <h4 class="search-item-title">Artists</h4>
          <h4 class="search-item-title">Titles</h4>
        </div>

        <div class="search-results" ref="searchResults">
          <div
            v-show="showArtistResults"
            class="search-item item search-results-artists"
            id="tab2"
          >
            <div v-for="item in artists" v-bind:key="item.objectID">
              <router-link
                class="search-result-link"
                v-bind:to="'/artist' + '?artist_name=' + item.artist_name"
              >
                <span v-html="item._highlightResult.artist_name.value" />
              </router-link>
            </div>
          </div>
          <div
            v-show="showTitleResults"
            class="search-item item active search-results-titles"
            id="tab1"
          >
            <div v-for="item in works" v-bind:key="item.objectID">
              <router-link
                class="search-result-link"
                v-bind:to="'/title/' + item.title"
              >
                <span v-html="item._highlightResult.title.value" />
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- </template>
    </ais-state-results> -->
  </div>
</template>
<script>
import { resizeComputed } from "@/services/resizeService";
import { songsSearchIndex } from "@/services/algoliaService";
export default {
  data() {
    return {
      selectedTab: "ARTIST",
      artists: [],
      works: [],
      query: "",
    };
  },
  mounted() {},
  computed: {
    ...resizeComputed,
    isMobile() {
      return this.screen.width < 768;
    },
    showTitleResults() {
      if (!this.isMobile) return true;
      return this.selectedTab === "TITLE";
    },
    showArtistResults() {
      if (!this.isMobile) return true;
      return this.selectedTab === "ARTIST";
    },
  },
  watch: {
    "screen.width"(width) {
      if (width > 767 && this.$options.searchBoxTranslated) {
        this.resetPageYPosition();
      }
    },
  },
  created() {
    const query = this.$route.query.query;
    if (query) {
      this.query = query;
      this.searchData(query);
    }
  },
  methods: {
    async searchData(query) {
      this.$refs.searchResults?.scrollTo({
        top: 0,
        left: 0,
      });
      try {
        const [artistsResult, titlesResult] = await Promise.all([
          this.searchArtists(query),
          this.searchTitles(query),
        ]);
        console.log("result", artistsResult, titlesResult);
        this.artists = artistsResult.hits;
        let works = [];
        for (const hit of titlesResult.hits) {
          for (let i = 0; i < hit.works.length; i++) {
            const highlightResult = hit._highlightResult.works[i];
            const matchLevel = highlightResult.title.matchLevel;
            if (matchLevel !== "none") {
              const item = hit.works[i];
              item._highlightResult = highlightResult;
              works.push(item);
            }
          }
        }
        this.works = works;

        if (query !== this.$route.query.query) {
          this.$router.push({
            query: {
              query,
            },
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    searchArtists(query) {
      return songsSearchIndex.search(query, {
        restrictSearchableAttributes: ["artist_name"],
        attributesToRetrieve: ["artist_id", "artist_name"],
      });
    },
    searchTitles(query) {
      return songsSearchIndex.search(query, {
        restrictSearchableAttributes: ["works.title"],
        attributesToRetrieve: ["works"],
      });
    },
    onFocusSearchInput(e) {
      if (this.screen.width > 767) return;
      if (this.$options.searchBoxTranslated) return;
      console.log("on focus", e, e.target.getBoundingClientRect());
      const { top: offsetTop } = e.target.getBoundingClientRect();
      this.$el.style.transform = `translateY(-${offsetTop - 40}px)`;
      this.$options.searchBoxTranslated = true;
    },
    onBlurSearchInput(query) {
      console.log("query", query);
      if (this.screen.width > 767) return;
      if (query) return;
      this.resetPageYPosition();
    },
    resetPageYPosition() {
      this.$el.style.transform = "translateY(0px)";
      this.$options.searchBoxTranslated = false;
    },
    getSearchPlaceholder(results) {
      const { nbHits } = results;
      return this.screen.width < 768
        ? `${nbHits.toLocaleString()} tracks`
        : `Search ${results.nbHits.toLocaleString()} songs ...`;
    },
    getUniqueArtists(hits) {
      let included = {};
      let items = [];
      for (const item of hits) {
        if (!Reflect.has(included, item.artist_name)) {
          included[item.artist_name] = true;
          item.legal_name = Object.prototype.hasOwnProperty.call(
            item,
            "legal_name"
          )
            ? item.legal_name
            : item.artist_name;
          items.push(item);
        }
      }
      return items;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .highlight-hit {
    background: transparent;
    font-weight: 600;
  }
}
.search-input {
  padding-left: 0;
  -webkit-appearance: textfield;

  &::-webkit-search-decoration {
    -webkit-appearance: none;
  }
  &::placeholder {
    color: $color-grey-light;
  }
}
.search-page {
  transition: 0.5s transform;
  background-color: $color-background;
  ::v-deep {
    .ais-Highlight-highlighted {
      color: $color-text;
      font-weight: 600;
      background-color: transparent;
    }
  }

  .search-result-link {
    font-family: "helveticaneue";
    font-weight: 400;
  }
}
.search-tab-menu a {
  text-decoration: none;
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
  font-size: 22px;
  color: #cad1db;
  font-weight: 500;
}

.search-tab-menu a.active {
  color: #bd7eee;
}

.search-tab-menu a:last-child {
  margin-right: 0;
}

.search-tab-menu {
  margin: 30px 0;
  display: block;
  @include tablet {
    display: none;
  }
}

.search-results-container {
  margin-top: 80px;
}

.search-results-headings {
  display: flex;
}

.search-results {
  display: flex;
  overflow-y: auto;
  height: 260px;

  &-titles {
    @include tablet-only {
      margin-right: 0.5rem;
    }
  }
  &-artists {
    @include tablet-only {
      margin-left: 0.5rem;
    }
  }
}

.search-results .item {
  width: 50%;
}

.search-results .item a {
  color: #373a3c;
  font-size: 22px;
  line-height: 44px;
  letter-spacing: 1px;
  display: block;
  text-decoration: none;
}

.search-item-title {
  color: #bd7eee;
  font-size: 24px;
  line-height: 26px;
  letter-spacing: 2px;
  margin: 0 0 15px;
  width: 50%;
}

@media only screen and (max-width: 767px) {
  h4.search-item-title {
    display: none;
  }

  .search-results .item.active {
    display: block;
  }

  .search-results .item {
    width: 100%;
    float: none;
  }

  .search-results-container {
    margin-top: 0;
  }
}

@media (max-width: 1070px) {
  .search-item-title {
    font-size: 18px;
  }

  .search-results .item a {
    font-size: 16px;
  }
}
</style>